
import './Header.scss';
import logoPaulette from '../components/logo-paulette.svg';
import logoStudioFilmiz from '../components/logo-studio-filmiz.svg';
import {ReactComponent as ReactLogos} from '../img/logos.svg';
import 'lite-youtube-embed';
import 'lite-youtube-embed/src/lite-yt-embed.css';

import scroll from '../img/scroll.svg';



export default () => {
  const bgImage = require('../img/header_gresy.png');
  return (
  // <div className="header" style={{backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}> 
  //    <div className="header__img">
  //     </div>
  //   </div>

  <div className="header"> 
    <div className="header__video">
      <lite-youtube  videoid="vQgOVw4wyZQ" params="loop=1&controls=0&showinfo=0&rel=0" style={{backgroundImage: `url(${bgImage})`}} >
      {/* <iframe style={{ width: '100%', height: '100%'}} src="https://www.youtube.com/embed/vQgOVw4wyZQ?controls=0&loop=1&modestbranding=1&playsinline=1&showinfo=0" title="GRESY SUR AIX   Tiers lieu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>` */}
      </lite-youtube>

    </div>

  </div>
  );
}