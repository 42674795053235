
import './Footer.scss';

export default () => {
  return (
    <section className='footer'>
      <svg xmlns="http://www.w3.org/2000/svg" width="214" height="144" viewBox="0 0 214 144" fill="none">
        <path d="M88.5824 72.3531C87.4568 73.236 86.4287 74.2425 85.3697 75.2116C85.8092 75.5659 86.4859 76.3119 86.6499 76.2125C87.8242 75.5003 88.8954 74.6165 90.5252 73.3916C89.378 72.7627 88.7295 72.2379 88.5824 72.3531Z" fill="black"/>
        <path d="M29.8632 28.1896C27.8679 27.781 26.9813 29.1568 26.3356 30.5926C23.6477 36.5682 18.54 38.8662 12.5288 39.5532C7.6713 40.108 4.47452 37.4773 3.72382 32.6488C3.53826 31.4567 3.54107 30.1877 3.72008 28.9937C4.95718 20.7445 8.67972 13.6706 14.2729 7.58628C15.6122 6.12894 17.2963 4.85248 19.0536 3.94152C25.0301 0.845019 30.6261 3.61819 32.0216 10.2114C32.2671 11.3735 32.4396 12.5516 32.6467 13.7221C32.8444 13.7849 33.0422 13.8486 33.2399 13.9114C33.6457 13.1785 34.2474 12.4916 34.4274 11.7071C36.0843 4.50009 29.9044 -1.60763 22.8277 0.380169C20.658 0.989347 18.4528 2.03526 16.6843 3.41856C6.96091 11.0192 1.05469 20.9254 0.0500105 33.3199C-0.460762 39.6281 2.96282 42.839 9.31796 42.8568C14.5578 42.8708 18.8558 40.3648 22.9935 37.5044C23.6018 37.0836 24.1894 36.6319 25.1669 35.915C25.0029 36.7809 24.9776 37.1296 24.8708 37.452C22.3488 45.0948 17.3394 49.7602 9.12208 50.5512C7.21582 50.7349 5.7313 51.4003 5.15118 53.4818C16.9505 55.1856 31.1678 40.5522 29.8632 28.1906V28.1896Z" fill="black"/>
        <path d="M68.1749 39.6657C66.816 39.8016 65.4374 39.7313 64.069 39.7941C62.488 39.8663 61.3718 40.5935 60.8629 42.1446C64.1525 44.0762 71.0296 41.9712 72.7362 39.3864C74.1046 37.3124 73.904 35.2496 71.9246 33.6414C70.7063 32.6517 69.3801 31.7933 67.8281 30.6799C70.2152 28.9657 72.5882 29.1297 74.9621 29.2291C76.2086 29.2816 77.5919 29.5655 78.4503 27.6124C77.1551 27.3125 75.904 26.6096 74.8965 26.8664C71.9462 27.618 68.92 28.4231 66.2508 29.8298C63.7204 31.1635 63.9435 32.6358 66.3211 34.3771C67.3642 35.1409 68.4598 35.8766 69.3305 36.8129C69.8 37.318 70.0962 38.2646 69.979 38.9244C69.9162 39.2777 68.8272 39.6011 68.174 39.6667L68.1749 39.6657Z" fill="black"/>
        <path d="M63.1412 15.499C60.1412 15.753 54.2838 20.7248 53.9342 23.1306C57.3821 21.0669 60.4946 18.9798 63.1412 15.499Z" fill="black"/>
        <path d="M77.0267 37.5325C76.8918 39.4735 76.9677 41.5962 79.1776 42.5259C81.2891 43.4153 82.9648 42.211 84.4456 40.9074C85.4653 40.0105 86.3153 38.913 87.1981 37.869C87.7811 37.1801 88.29 36.4266 89.155 35.2701C89.1841 42.5034 85.4521 48.3525 85.0388 55.0581C86.1925 54.3862 87.23 53.597 87.6574 52.5577C88.8336 49.6955 90.0088 46.7874 90.7108 43.7874C91.6236 39.8858 92.1494 35.8868 92.6854 31.9093C92.8138 30.9562 92.3265 29.9197 92.1231 28.9216C91.8682 28.84 91.6123 28.7576 91.3574 28.676C90.4811 29.6245 89.4765 30.4848 88.7567 31.5392C87.2562 33.7388 86.0079 36.1146 84.4409 38.2607C83.8945 39.0105 82.4897 39.8896 81.8824 39.6562C81.0417 39.3329 80.2854 38.0902 80.0342 37.1033C79.6874 35.7406 79.6818 34.2327 79.8271 32.8175C80.0305 30.8372 80.5075 28.885 80.8674 26.9206C80.6715 26.7988 80.4756 26.677 80.2798 26.5542C79.5309 27.795 78.4541 28.9516 78.1055 30.2964C77.4953 32.646 77.1964 35.1024 77.0277 37.5316L77.0267 37.5325Z" fill="black"/>
        <path d="M109.311 82.2303C115.451 81.3662 121.583 80.4543 127.728 79.6324C129.089 79.4506 129.312 78.6502 129.365 77.5546C129.552 73.7355 129.753 69.9165 129.952 66.0974C130.431 56.8744 130.9 47.6514 131.411 38.4304C131.457 37.6019 131.521 36.346 132.028 36.0424C135.326 34.063 138.749 32.2917 142.452 30.2777C142.464 46.6805 141.79 62.5576 142.078 78.429C142.457 78.4299 142.837 78.4309 143.216 78.4328V30.8184C143.472 30.7257 143.728 30.6329 143.984 30.5401C146.326 32.0359 148.622 33.6085 151.026 34.9955C152.389 35.7818 152.847 36.7584 152.869 38.3029C152.971 45.5896 153.176 52.8754 153.37 60.1612C153.54 66.5594 153.745 72.9567 153.951 79.9182C154.676 78.907 155.106 78.4178 155.415 77.862C159.633 70.2707 165.846 64.5651 172.457 59.0909C178.891 53.7638 185.141 48.1519 190.979 42.1838C198.645 34.346 204.134 25.0472 207.242 14.4484C207.531 13.4643 207.685 12.4419 207.839 11.7296C201.556 12.4334 195.495 13.4737 189.401 13.7324C162.804 14.8645 136.344 12.604 109.903 10.0961C105.836 9.70993 101.772 9.29663 97.4089 8.86646C100.987 33.5185 104.513 57.8172 108.044 82.1441C108.612 82.1862 108.972 82.2772 109.311 82.2294V82.2303Z" fill="black"/>
        <path d="M150.135 62.1263C150.135 60.1198 150.078 58.0542 150.153 55.9942C150.213 54.3138 149.511 53.64 147.64 53.8827C147.64 56.1245 147.701 58.341 147.62 60.5518C147.556 62.2978 148.309 62.7018 150.135 62.1254V62.1263Z" fill="black"/>
        <path d="M89.3959 81.0326C89.1185 81.876 88.469 82.8067 88.6414 83.5452C89.0097 85.1244 89.6808 86.6726 90.4643 88.1047C91.0725 89.2162 91.2721 89.9969 90.1288 90.8422C88.1663 92.293 86.2787 93.8459 84.3163 95.2967C83.8861 95.6144 83.2722 95.6819 82.7427 95.8647C83.0304 96.1852 83.3191 96.5057 83.6068 96.8253C87.2572 96.4082 89.1737 93.0287 92.169 91.2986C93.1222 92.8206 93.9675 94.2377 94.8832 95.6079C95.4567 96.4673 95.9366 97.6969 97.3686 96.7916C98.7088 95.9453 98.9637 94.8637 98.0828 93.5048C97.0275 91.8778 96.0144 90.2246 94.8785 88.4167C97.3902 86.2096 99.8887 84.0147 102.387 81.8189C102.131 81.5152 101.874 81.2106 101.618 80.907C99.0743 82.8929 96.5298 84.8779 93.8138 86.9988C92.5495 85.0044 91.3002 83.0325 90.051 81.0607C89.8335 81.0513 89.6152 81.0419 89.3977 81.0326H89.3959Z" fill="black"/>
        <path d="M109.849 83.7206C108.327 83.9699 108.024 84.6924 108.242 86.0729C109.029 91.0523 109.777 96.0391 110.476 101.032C110.667 102.389 111.265 102.913 112.656 103.151C127.415 105.674 141.598 109.721 154.225 119.635C151.306 106.847 148.523 94.5677 153.898 81.7168C151.117 81.266 148.76 80.6616 146.377 80.536C134.06 79.8846 121.948 81.7412 109.85 83.7215L109.849 83.7206Z" fill="black"/>
        <path d="M165.009 135.046C162.792 128.802 160.345 122.629 158.38 116.306C155.902 108.33 154.616 100.126 155.676 91.745C157.084 80.6064 163.124 72.0704 171.303 64.8605C175.686 60.9964 180.236 57.3217 184.712 53.5626C196.7 43.4924 205.02 31.0202 209.334 15.9304C209.466 15.4674 209.502 14.9763 209.583 14.4993C208.432 16.6783 207.663 18.9257 206.729 21.1018C200.202 36.3191 189.356 47.9685 176.477 58.0246C172.003 61.5175 167.681 65.3132 163.828 69.4696C155.813 78.1172 151.835 88.4582 152.876 100.346C153.308 105.28 154.368 110.166 155.283 115.047C155.81 117.861 156.619 120.621 157.301 123.404C157.162 123.504 157.022 123.603 156.883 123.703C156.537 123.472 156.165 123.272 155.848 123.006C151.107 119.013 145.736 116.108 140.016 113.796C131.202 110.234 122.01 108.141 112.646 106.717C101.593 105.034 90.4699 104.454 79.3013 104.554C81.081 104.828 82.8645 105.077 84.6555 105.192C95.9056 105.919 107.055 107.367 118.009 110.062C129.642 112.925 140.814 116.928 150.547 124.164C157.229 129.132 162.587 135.14 164.882 143.407C164.926 143.564 165.153 143.67 165.269 143.775C166.926 142.985 167.256 141.745 166.725 140.167C166.15 138.461 165.611 136.743 165.008 135.046H165.009Z" fill="black"/>
        <path d="M213.79 23.8083C213.685 21.3003 213.422 18.799 213.232 16.2938C213.065 16.2526 212.898 16.2114 212.732 16.1692C212.412 16.8721 212.064 17.5647 211.774 18.2798C205.832 32.9516 196.962 45.3835 184.299 55.0919C178.875 59.2512 173.677 63.7591 168.742 68.4911C162.737 74.2473 158.559 81.1779 157.348 89.6117C156.53 95.3099 156.785 100.987 157.902 106.609C158.611 110.174 159.557 113.692 160.305 116.842C166.541 113.979 172.361 111.149 178.304 108.609C193.21 102.235 203.608 91.6923 208.313 76.0449C213.436 59.0047 214.532 41.4876 213.791 23.8073L213.79 23.8083Z" fill="black"/>
        <path d="M60.8835 38.9446C56.967 38.4263 53.4768 40.6756 49.3607 38.7909C51.4431 37.685 53.1826 36.968 54.6821 35.9109C56.4843 34.641 58.2228 33.2117 59.7167 31.5998C60.7008 30.5379 61.2537 28.7722 59.8676 27.7291C58.9576 27.044 57.1638 26.6682 56.176 27.0975C51.2182 29.254 47.6053 32.9081 45.8368 38.0852C44.9502 40.6803 46.1433 42.625 48.8733 42.5041C52.2622 42.3541 55.6352 41.4975 58.9698 40.7449C59.7055 40.5791 60.25 39.5669 60.8835 38.9446ZM54.8536 29.8144C55.5087 29.6523 56.7823 30.0122 56.9829 30.4742C57.2594 31.1105 57.0757 32.4732 56.5846 32.8284C54.3081 34.4742 51.8574 35.8799 49.2013 37.5388C49.4131 34.417 52.415 30.4189 54.8536 29.8144Z" fill="black"/>
        <path d="M79.7633 97.1017C79.9883 97.1861 80.2123 97.2704 80.4372 97.3548C81.1054 96.3604 82.0351 95.4523 82.395 94.3557C83.5627 90.7981 84.5431 87.1787 85.544 83.5676C85.752 82.8179 85.737 82.0053 85.8242 81.2218C85.5833 81.1572 85.3415 81.0935 85.1007 81.0288C82.7877 84.8666 81.5019 89.1824 79.9339 93.3689C76.3248 91.5919 75.56 90.3098 75.9827 86.3211C76.1645 84.6108 76.3585 82.8104 77.0277 81.2584C79.3885 75.7777 82.0023 70.4075 84.4109 64.9465C84.9207 63.79 85.5627 61.6391 85.1194 61.3055C83.5721 60.1386 82.9339 62.1199 81.947 62.9024C81.9002 62.9399 81.8167 62.9306 81.4381 63.0027C81.4381 61.7731 81.529 60.686 81.4213 59.6176C81.0623 56.0393 79.0811 54.1902 75.5263 54.5126C73.5619 54.6907 71.5113 55.2127 69.7456 56.0806C62.4824 59.6522 56.8817 65.0467 53.1151 72.2379C50.862 76.5396 49.7768 81.1328 50.2219 86.0222C50.7421 91.7381 53.8826 96.008 58.3643 96.755C63.4008 97.5947 67.532 95.4663 71.1589 92.2452C71.6678 91.7934 71.5544 90.6416 71.8487 89.2433C69.9387 90.5263 68.6838 91.4804 67.3277 92.2592C62.8647 94.8234 58.6436 93.9987 55.8517 89.6904C54.7242 87.95 53.9304 85.7438 53.7486 83.6829C52.8489 73.4665 59.6108 62.4695 69.0699 58.4348C70.248 57.9325 71.5338 57.5942 72.8028 57.4142C75.873 56.9784 77.8749 58.4639 78.2788 61.5332C78.4747 63.0205 78.5975 64.6194 78.2695 66.0561C76.7971 72.504 75.2498 78.9388 73.5207 85.3221C72.7803 88.0568 72.4157 90.6922 73.4204 93.367C74.4148 96.0155 76.1195 96.6472 78.6837 95.4569C78.9189 95.3473 79.1888 95.3117 79.4119 95.2508C79.545 95.9546 79.6537 96.5272 79.7624 97.1008L79.7633 97.1017Z" fill="black"/>
        <path d="M17.5709 91.4288C16.8905 90.8515 16.1426 90.3538 15.4257 89.8206C15.4716 89.6237 15.5185 89.4269 15.5644 89.2292C17.5306 88.9649 19.4959 88.7015 21.4622 88.4373C21.4153 88.1964 21.3684 87.9555 21.3225 87.7147C18.8802 86.6491 16.6468 87.5694 14.5297 88.8103C12.5775 89.9546 12.63 90.7549 14.376 92.1682C15.2232 92.8543 15.9121 93.7362 16.7837 94.6453C14.5972 95.872 12.0939 94.2676 10.8606 96.6583C13.198 97.6537 16.3891 97.0538 17.8746 95.3931C19.0386 94.0914 18.943 92.5918 17.5709 91.4278V91.4288Z" fill="black"/>
        <path d="M30.1893 88.4805C29.9766 88.3831 29.7629 88.2856 29.5502 88.1881C28.9729 88.9332 28.3131 89.6305 27.8379 90.4364C27.1809 91.5508 26.8107 92.861 26.0329 93.8675C25.5502 94.4936 24.5418 94.7138 23.7695 95.1168C23.389 94.2508 22.6983 93.3802 22.7114 92.5208C22.7264 91.5864 23.3834 90.6567 23.7827 89.7345C24.0741 89.0616 24.4021 88.4037 24.7133 87.7392C24.539 87.6474 24.3637 87.5555 24.1894 87.4646C22.3656 89.1019 21.0592 91.1506 20.7124 93.5995C20.5653 94.6388 20.9973 96.2049 21.7452 96.786C22.3525 97.2583 23.9617 96.8816 24.8801 96.4055C26.1022 95.7729 27.0853 94.6791 28.2756 93.697C28.4293 94.1487 28.4518 94.4702 28.6205 94.6623C29.1575 95.2705 29.8857 96.4008 30.2934 96.3024C31.493 96.0128 31.7638 94.9959 31.388 93.7223C30.8819 92.0063 30.5792 90.2293 30.1893 88.4796V88.4805Z" fill="black"/>
        <path d="M46.6147 29.9379C46.371 28.1431 44.8687 26.8695 43.1208 26.9623C41.1827 27.0663 39.2549 27.7992 37.3795 28.4281C36.5004 28.7223 35.7554 29.4149 34.8566 29.9866C34.7498 28.9042 34.6701 28.0944 34.5773 27.1488C32.7001 28.3325 29.2859 38.8122 29.7939 43.0661C30.6064 42.1496 31.6823 41.3558 32.1762 40.2911C33.1125 38.2743 33.6945 36.0953 34.463 33.9978C35.2362 31.8863 36.6316 30.4318 38.9446 30.086C41.1208 29.7607 42.2661 31.1375 43.1677 32.7963C43.8349 34.0241 44.8068 34.16 45.5275 33.1487C46.1676 32.2499 46.7543 30.9566 46.6165 29.9388L46.6147 29.9379Z" fill="black"/>
        <path d="M40.4385 87.5112C38.8088 87.5281 37.1855 88.1738 35.4348 88.5928C35.3374 88.4231 35.1059 88.0201 34.4939 86.9536C33.447 90.6227 32.567 93.7108 31.686 96.7979C31.9391 96.9301 32.1921 97.0632 32.4452 97.1953C32.925 96.5224 33.5239 95.9039 33.8566 95.1644C34.4152 93.9245 34.7151 92.5684 35.284 91.3341C36.3158 89.096 39.2061 88.4803 40.3514 90.8364C40.4976 91.1363 40.8031 91.3594 41.4273 92.0585C41.9296 90.9939 42.774 90.0295 42.5613 89.4587C42.2501 88.6228 41.1761 87.5019 40.4385 87.5094V87.5112Z" fill="black"/>
      </svg>

      {/* <a href="https://gresy-sur-aix.fr/contact/" target="_blank">Charte de protection des données personnelles</a> */}
    </section>
  )
}