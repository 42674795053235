
import './App.css';
import './checkbox.scss';
import './fonts.scss';
import 'normalize.css';
import classNames from 'classnames';
import { useState } from 'react';

import Header from './sections/Header';
import Form from './sections/Form';
import Intro from './sections/Intro';
import Footer from './sections/Footer';
import Finish from './sections/Finish';
import AnimIntro from './sections/AnimIntro';
import ResponseSelector from './sections/ResponseSelector';



function App() {

  const [ step, setStep ] = useState(0);


  return (
    <div className="App">
      {step === 0 && (
        <>
          {/* <AnimIntro /> */}
          <Header />
          <Intro />
          {/* <section>
            <h4 className='booking__subtitle'>Habitantes et habitants de Grésy-sur-Aix, 
            ce lieu doit vous ressembler et rassembler :</h4>
            <h3 className="booking__title">À VOS VOTES !</h3>
            <Form onSuccess={() => { setStep(1) }} />
          </section> */}
          <Footer />
        </>
      )}
      {step === 1 && (
        <Finish onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setStep(0)
        }} />
      )}
    </div>
  );
}

export default App;
