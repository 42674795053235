import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDocs, writeBatch, connectFirestoreEmulator } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export default ({ children, form, onSuccess, guests }) => {
  const { register, handleSubmit, watch, formState: { errors } } = form;

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAOEepUEgyeGZcFC3X2IoTka9qoK3MMHWc",
    authDomain: "gresy-sur-aix-nom-tiers-lieu.firebaseapp.com",
    databaseURL: "https://gresy-sur-aix-nom-tiers-lieu-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gresy-sur-aix-nom-tiers-lieu",
    storageBucket: "gresy-sur-aix-nom-tiers-lieu.appspot.com",
    messagingSenderId: "118522250013",
    appId: "1:118522250013:web:02400039d25d2dfaf597f4"
  };
  
  
  

  initializeApp(firebaseConfig)
  // add after initializeApp
  const db = getFirestore()
  if (window.location.host.includes('localhost')) {
    connectFirestoreEmulator(db, 'localhost', 8089
    )
  }

  const prepareData = (data) => {

    const derivedData = { ...data };

    let people = [];

    Object.entries(derivedData.people).forEach(([ id, guest]) => {
      const newID = uuidv4();
      people.push({
        ...guest,
        old_id: guest.id,
        id: newID,
        index: +guest.index,
        // link_id: guest.id === guest.link_id ? newID: guest.link_id,
      });
    });

  
    people = people.map((guest) => {
      const oldNextID = people.findIndex(({ old_id }) => old_id === guest.next_link)
      const oldLinkID = people.findIndex(({ old_id }) => old_id === guest.link_id)
      console.log('-link', guest.link_id, oldLinkID, people[oldLinkID])


      // delete guest.add;
      // delete guest.old_id;


      return {
        ...guest,
        next_link: people[oldNextID] ? people[oldNextID].id: null,
        link_id: oldLinkID !== -1 ? people[oldLinkID].id: null,
      }
    });


    const peopleObject = {};
    people.forEach(guest => {
      peopleObject[guest.id] = guest;
    })

    console.log('-->', data.people, peopleObject)

    derivedData.people = peopleObject;

    return derivedData;
  }


  const onSubmit = data => {
    const ids = guests.map(({ id }) => id);

    const { people } = prepareData(data);
    const batch = writeBatch(db);

    console.log('people', people)
    Object.entries(people).forEach(([ key, value]) => {
      const data = { ...value };
      

      if (!ids.includes(data.old_id)) {
        return;
      }

      delete data.add;
      delete data.old_id;
      console.log('add', data)

      const sfRef = doc(db, 'booking-list', data.id);
      batch.set(sfRef, data)
    })

    batch.commit().then(() => {
        onSuccess();
    });
    
    // db.collection("users").add({
    //     first: "Ada",
    //     last: "Lovelace",
    //     born: 1815
    // })
    // .then((docRef) => {
    //     console.log("Document written with ID: ", docRef.id);
    //     onSuccess();
    // })
    // .catch((error) => {
    //     console.error("Error adding document: ", error);
    // });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      { children }
    </form>
  );
} 