import "./Intro.scss";
import scroll from '../img/scroll.svg';
import { useRef } from "react";

// eslint-disable-next-line import/no-anonymous-default-export

export default () => {
  const nextSectionRef = useRef(null);

  const handleScrollClick = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const stairsImage = require('../img/stairs.jpg');
  const upstairsImage = require('../img/upstairs.jpg');
  const officeImage = require('../img/office.jpg');

  return (
    <section className="intro">
      <div className="Scroll_Button" onClick={handleScrollClick}>
        <div className="Scroll_Button__container">
          <img className={"Scroll_Button__Circle"} src={scroll} alt="scroll"></img>
          <svg  className={"Scroll_Button__Arrow"} viewBox="0 0 23 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8984 2.03564C12.8984 1.20722 12.2269 0.535645 11.3984 0.535645C10.57 0.535645 9.89844 1.20722 9.89844 2.03564H12.8984ZM10.3378 65.0963C10.9236 65.6821 11.8733 65.6821 12.4591 65.0963L22.005 55.5504C22.5908 54.9646 22.5908 54.0148 22.005 53.429C21.4193 52.8433 20.4695 52.8433 19.8837 53.429L11.3984 61.9143L2.91316 53.429C2.32737 52.8433 1.37762 52.8433 0.791836 53.429C0.20605 54.0148 0.20605 54.9646 0.791836 55.5504L10.3378 65.0963ZM9.89844 2.03564V64.0356H12.8984V2.03564H9.89844Z" fill="white"/>
          </svg>
        </div>
 
      </div>
      <div className="titles" ref={nextSectionRef}>
        <h1>grésy-sur-aix</h1>
        <h2>tiers-lieu</h2>
      </div>
      <p className="description">
        Plus que quelques semaines avant le début des travaux du tiers-lieu
        culturel, associatif et musical !
      </p>
      <div className="arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="91"
          viewBox="0 0 16 91"
          fill="none"
        >
          <path
            d="M7.2929 90.7071C7.68342 91.0976 8.31659 91.0976 8.70711 90.7071L15.0711 84.3431C15.4616 83.9526 15.4616 83.3195 15.0711 82.9289C14.6805 82.5384 14.0474 82.5384 13.6569 82.9289L8 88.5858L2.34315 82.9289C1.95263 82.5384 1.31946 82.5384 0.928936 82.9289C0.538411 83.3195 0.538411 83.9526 0.928936 84.3431L7.2929 90.7071ZM7 4.37114e-08L7 90L9 90L9 -4.37114e-08L7 4.37114e-08Z"
            fill="black"
          />
        </svg>
      </div>
      <h3 className="wait">PATIENCE</h3>
      <p className="description description__second">
       Pour les résultats du nom, rendez-vous aux vœux du Maire le 12 janvier à 18h30.
      </p>
   {/*
      <div className="grid-images">
        <img src={officeImage} alt="" />
        <img src={stairsImage} alt="" />
        <img src={upstairsImage} alt="" />
      </div>

      <div className="divider"></div>
      <div className="content">
        <div className="content__left">
          <p>
            Ce lieu dédié aux Grésyliennes et Grésyliens impactera favorablement
            la dynamique associative, culturelle et d’animation de notre
            commune.
          </p>
        </div>
        <div className="content__right">
          <span>
            Ce bâtiment unique en son genre et le plus ouvert possible veut
            favoriser les échanges, le partage et les idées. Il s’articule
            autour de 4 pôles :
          </span>
          <ul className="content__right__list">
            <li>
              Un pôle culture et médiathèque, pour favoriser la convivialité,
              mêlant espaces de présentation des collections, de consultation et
              de lecture. La ludothèque sera aussi valorisée.
            </li>
            <li>
              Un pôle associatif, pour renforcer les synergies, avec 2 salles
              d’activités mutualisables en espace de conférence.
            </li>
            <li>
              Un pôle son et image pour libérer la créativité comprenant 3
              studios de pratique et d’enregistrement « musique » et 1 studio de
              captation « image ».
            </li>
            <li>
              Des espaces d’accueil généreux et de vie sociale tels un « forum »
              en jonction de tous les autres pôles pour développer la proximité.
              Cet espace ouvrira sur un large patio végétalisé et la future
              esplanade et comprendra également un café-bistrot et des espaces
              d’exposition.
            </li>
          </ul>

          <p>La volonté est de créer un lieu fédérateur, vivant, incitant le
          brassage des publics grâce à un équipement hybride, décloisonné,
          flexible pour favoriser une grande diversité d’usages. En somme, le
          point de convergence des citoyennes et citoyens.</p>
          <p>L’architecture est la plus accueillante possible, confortable, généreuse, soigneusement
          insérée dans le tissu urbain et végétal du nouveau "cœur de vie"
          avec une esplanade et un parc urbain de 6.000m².</p>
          <p>Le petit
          plus ? Ce bâtiment s’inscrit dans une stratégie immobilière globale
          et de rationalisation de nos équipements grâce à l’optimisation
          énergétique des anciens bâtiments dont certains seront démolis.</p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="content">
        <div className="content__left">
          <p>
            Il est grand temps de lui trouver un nom fédérateur. Et cela, grâce
            à vous !
          </p>
        </div>
        <div className="content__right">
          <span>
            À présent que vous savez à quoi va ressembler ce bâtiment et pour
            que vous puissiez de mieux en mieux vous approprier ces
            fonctionnalités, à vous de voter et d’élire le meilleur nom parmi 4
            propositions :
          </span>
          <ul className="content__right__list">
            <li>
              <div className="alt">
                  <span>La balise</span>
                  <p>Comme point d’ancrage local</p> 
              </div>
            </li>
            <li>
              <div className="alt">
                <span>Le métronome</span>
                 <p>Comme un projet à ondes positives</p>
              </div>
            </li>
            <li>
              <div className="alt">
                <span>L’esquisse</span>
                {/* <p>Comme le début d’un projet ou d’une création, avant de devenir grand</p>
              </div>
            </li>
            <li>
              <div className="alt">
                <span>L’embarcadère</span>
                {/* <p>Comme point de rassemblement vers de nouveaux horizons</p>
              </div>
            </li>
          </ul>
          <p>
            <span>
              Vous avez jusqu’au 13 décembre pour nous faire part de votre choix
              soit :
            </span>
            <ul>
              <li>
                par internet via le Facebook ou le site internet de la Commune
              </li>
              <li>par un bulletin de vote mis à disposition à la Mairie</li>
            </ul>
            <br />
            Les enfants ne seront pas en reste car une urne circulera à l’école élémentaire pour recueillir leurs préférences. Il en a été de même pour nos aînés lors de leur traditionnel repas offert par le Mairie.
          </p>
      </div>
      </div>
       */}
    </section>
  );
};
